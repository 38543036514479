import React from "react";
import { Flex, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';

export const LoadingSpinner = (props: { size?: string }) => {

  return (
    <Flex  align="center" >
     <Spin indicator={<LoadingOutlined spin />} size="large" />
    </Flex>
  );
};

LoadingSpinner.displayName = "LoadingSpinner";