import React from "react";
import { Flex, Typography } from "antd";

type FormHeaderProps = {
  icon: React.ReactNode;
  title: string;
};

export const FormHeader = ({ icon, title }: FormHeaderProps) => {
  const { Title } = Typography;

  return (
    <Flex
      align="center"
      justify="start"
      style={{
        gap: "0.5rem",
        borderBottom: "1px solid var(--layout-border-color)",
        paddingBottom: "0.5rem",
      }}
    >
      <Flex style={{ fontSize: "1.5rem" }}>{icon}</Flex>
      <Title level={2}>{title}</Title>
    </Flex>
  );
};
