import React, { createContext, ReactNode, useEffect, useState } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest, graphConfig } from "authConfig";
import { MsGraphUser } from "common/types";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

export const UserContext = createContext<MsGraphUser | null>(null);

export const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<MsGraphUser | null>(null);
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const account = accounts[0];
        if (account) {
          const tokenResponse = await instance.acquireTokenSilent({
            ...loginRequest,
            account,
          });
          const userProfileResponse = await fetch(graphConfig.graphMeEndpoint, {
            headers: {
              Authorization: `Bearer ${tokenResponse.accessToken}`,
            },
          }).then((res) => res.json());

          const photoResponse = await fetch(
            `${graphConfig.graphMeEndpoint}/photo/$value`,
            {
              headers: {
                Authorization: `Bearer ${tokenResponse.accessToken}`,
              },
            }
          );
          if (photoResponse.ok) {
            const blob = await photoResponse.blob();
            setUser({
              ...userProfileResponse,
              profilePhoto: URL.createObjectURL(blob),
            });
          } else {
            setUser({
              ...userProfileResponse,
              profilePhoto: null,
            });
          }
        }
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(loginRequest);
        } else {
          console.error("Token acquisition failed:", error);
        }
      }
    };

    if (isAuthenticated) {
      fetchUserProfile();
    }
  }, [accounts, instance, isAuthenticated]);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};
