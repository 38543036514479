import { theme } from "antd";

export const getColourFromNumber = (id: number) => {
  const colors = [
    "#ff6969",
    "#ffe043",
    "#84ff4b",
    "#69ffd7",
    "#67e8ff",
    "#5c7afffb",
    "#d069ff",
    "#ff69d0",
  ];
  return colors[id % colors.length];
};

export const themeConfig = {
  algorithm: theme.darkAlgorithm,
  token: {
    colorPrimary: "#c80000",
    fontSize: 16,
    fontSizeHeading1: 32,
    fontSizeHeading2: 20.8,
    fontWeightStrong: 400,
  },
  components: {
    Typography: {
      titleMarginBottom: 0,
    },
    Layout: {
      headerBg: "#0e0d0d",
      colorText: "white",
      siderBg: "#181818",
      bodyBg: "#212121",
      headerHeight: "4.5rem",
      headerPadding: "0.6rem var(--content-padding)",
    },
    Menu: {
      colorPrimary: "white",
      activeBarHeight: 0,
      activeBarBorderWidth: 0,
      horizontalItemSelectedBg: "#444444",
      horizontalItemHoverBg: "#333333",
      horizontalItemBorderRadius: 8,
      popupBg: "#333333",
      itemSelectedBg: "#555555",
      itemHeight: "2.3rem",
      subMenuItemBorderRadius: 8,
    },
    Divider: {
      margin: 0,
      marginLG: 0,
    },
  },
};
