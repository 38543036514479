import styled from "styled-components";
import { Flex, Table, Typography, Input, Button, Popconfirm, Col } from "antd";

export const TableContainer = styled(Col)`
  flex-grow: 1;
`;

export const StyledRow = styled(Flex)`
  flex-direction: row;
`;

export const StyledCrudTable = styled(Table)`
  width: 100%;
`;

export const StyledTableTitle = styled(Typography)`
  font-size: 1.6rem;
  color: white;
`;

export const StyledCrudSearch = styled(Input.Search)`
  margin-bottom: 1.2rem;
`;

export const InsertEntryButton = styled(Button)`
  font-size: 1rem;
`;

export const DeleteEntryPopconfirm = styled(Popconfirm).attrs({
  overlayStyle: {
    border: "0.05rem solid #4a4a4a",
    borderRadius: "0.5rem",
  },
})``;

export const DeleteEntryButton = styled(Button)`
  margin-left: 0.5rem;
`;
