import React, { useState } from "react";
import { ConfigProvider, Layout } from "antd";
import { BrowserRouter } from "react-router-dom";
import { PageContents } from "routes/PageContents";
import { Navbar, Sidebar } from "common/components";
import { sidebar } from "common/enums";
import { Content } from "antd/es/layout/layout";
import { useIsAuthenticated } from "@azure/msal-react";
import { LookupContextProvider, UserContextProvider } from "common/context";
import { themeConfig } from "common/utils";

export const App = () => {
  const [page, setPage] = useState(window.location.pathname.slice(1));
  const isAuthenticated = useIsAuthenticated();
  const isSidebarEnabled = () => {
    return sidebar.map((a) => a.key).includes(page);
  };

  return (
    <ConfigProvider theme={themeConfig}>
      <UserContextProvider>
        <LookupContextProvider>
          <BrowserRouter>
            <Layout>
              <Navbar page={{ get: page, set: setPage }} />
              <Layout>
                {isAuthenticated && isSidebarEnabled() && (
                  <Sidebar page={{ get: page, set: setPage }} />
                )}
                <Content>
                  <PageContents />
                </Content>
              </Layout>
            </Layout>
          </BrowserRouter>
        </LookupContextProvider>
      </UserContextProvider>
    </ConfigProvider>
  );
};
