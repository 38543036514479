import React, { createContext, ReactNode, useEffect, useState } from "react";
import { useAPI } from "common/hooks";
import { Lookups } from "common/types/lookups";
import { useIsAuthenticated } from "@azure/msal-react";

const baseLookups: Lookups = {
  billingType: [],
  contactType: [],
  currency: [],
  environments: [],
  priority: [],
  rateTypes: [],
  serviceCategory: [],
  serviceHours: [],
  slaTypes: [],
  supportAreas: [],
  supportBracketWorkAllowed: [],
  supportCountry: [],
  workItems: [],
  workType: [],
};

export const LookupContext = createContext<Lookups>(baseLookups);

type Props = {
  children: ReactNode;
};

export const LookupContextProvider = ({ children }: Props) => {
  const [lookups, setLookups] = useState<Lookups>(baseLookups);
  const isAuthenticated = useIsAuthenticated();
  const api = useAPI();

  useEffect(() => {
    const getData = async () => {
      try {
        const data = await api.get("/lookups");
        setLookups(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (isAuthenticated) {
      getData();
    }
  }, [api, isAuthenticated]);

  return (
    <LookupContext.Provider value={lookups}>{children}</LookupContext.Provider>
  );
};
