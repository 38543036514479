import React, { useEffect, useState } from "react";
import { FormItem, CrudField } from "common/types";
import { Button, Flex, Popover, TablePaginationConfig } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import {
  DeleteEntryButton,
  DeleteEntryPopconfirm,
  StyledCrudTable,
  InsertEntryButton,
  TableContainer,
} from "common/components/styled";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { GenericForm } from "common/components";

type TableProps<TableType extends object, FormType extends object> = {
  idKey: keyof TableType;
  fields: Array<CrudField<TableType & FormType>>;
  dataSource: Array<TableType>;
  pagination?: false | TablePaginationConfig;
  sticky?: boolean;
  scroll?: {
    x?: number | true | string;
    y?: number | string;
    scrollToFirstRowOnChange?: boolean;
  };
  size?: SizeType;
  formTitleIcon: React.ReactNode;
  onCreate: (entity: FormType) => Promise<void>;
  onRead: (id: number) => Promise<FormType>;
  onUpdate: (entity: FormType, id: number) => Promise<void>;
  onDelete: (id: number) => Promise<void>;
};

export const GenericCrudTable = <
  TableType extends object,
  FormType extends object
>({
  idKey,
  fields,
  dataSource,
  pagination,
  sticky,
  scroll,
  size,
  formTitleIcon,
  onCreate,
  onRead,
  onUpdate,
  onDelete,
}: TableProps<TableType, FormType>) => {
  const [formTitle, setFormTitle] = useState<string>("");
  const [formItems, setFormItems] = useState<Array<FormItem<FormType>>>([]);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [tableHeight, setTableHeight] = useState(100);
  const [cellHeight, setCellHeight] = useState(100);
  const [editID, setEditID] = useState<number | null>(null);

  useEffect(() => {
    const resizeObserverTable = new ResizeObserver((event) => {
      setTableHeight(event[0].contentBoxSize[0].blockSize);
    });
    const resizeObserverCell = new ResizeObserver((event) => {
      setCellHeight(event[0].contentBoxSize[0].blockSize);
    });

    resizeObserverTable.observe(
      document.getElementById("tableContainer") as Element
    );
    resizeObserverCell.observe(
      document.getElementsByClassName("ant-table-cell")[0] as Element
    );
  }, []);

  const handleFormOpen = async (title: string, id?: number) => {
    if (id !== undefined) {
      const entity: FormType = await onRead(id);
      const formItems: Array<FormItem<FormType>> = fields
        .filter((field) => field.formOptions !== undefined)
        .map(
          (field) =>
            ({
              ...field.formOptions,
              initialValue: entity[field.dataIndex as keyof FormType],
              name: field.dataIndex,
              label: field.title,
            } as FormItem<FormType>)
        );
      setFormItems(formItems);
      setEditID(id);
    } else {
      const formItems: Array<FormItem<FormType>> = fields
        .filter((field) => field.formOptions !== undefined)
        .map(
          (field) =>
            ({
              ...field.formOptions,
              name: field.dataIndex,
              label: field.title,
            } as FormItem<FormType>)
        );
      setFormItems(formItems);
      setEditID(null);
    }
    setFormTitle(title);
    setIsFormVisible(true);
  };

  const handleFormClose = () => {
    setIsFormVisible(false);
  };

  const handleFormSubmit = async (entity: FormType) => {
    if (editID) {
      await onUpdate(entity, editID);
    } else {
      await onCreate(entity);
    }
    handleFormClose();
  };

  return (
    <TableContainer id="tableContainer">
      <GenericForm<FormType>
        titleIcon={formTitleIcon}
        title={formTitle}
        formItems={formItems}
        visible={isFormVisible}
        onCancel={handleFormClose}
        onSubmit={handleFormSubmit}
      />
      <StyledCrudTable
        columns={[
          ...fields
            .filter((item) => item.tableOptions !== undefined)
            .map((item) => {
              return {
                title: item.title,
                dataIndex: item.dataIndex,
                filters: item.tableOptions?.filters,
                onFilter: item.tableOptions?.filters
                  ? (value: boolean | React.Key, record: any) => {
                      const renderedValue = item.tableOptions?.render
                        ? item.tableOptions?.render(record[item.dataIndex])
                        : String(record[item.dataIndex]);
                      return String(renderedValue).includes(String(value));
                    }
                  : undefined,
                sorter: item.tableOptions?.sort
                  ? (a: any, b: any) => {
                      const aValue = item.tableOptions?.render
                        ? item.tableOptions.render(a[item.dataIndex])
                        : a[item.dataIndex];
                      const bValue = item.tableOptions?.render
                        ? item.tableOptions.render(b[item.dataIndex])
                        : b[item.dataIndex];
                      if (
                        typeof aValue === "number" &&
                        typeof bValue === "number"
                      ) {
                        return aValue - bValue;
                      } else {
                        return String(aValue).localeCompare(String(bValue));
                      }
                    }
                  : undefined,
                sortDirections: item.tableOptions?.sort
                  ? (["ascend", "descend"] as ("ascend" | "descend")[])
                  : undefined,
                render: item.tableOptions?.render,
              };
            }),
          ...[
            {
              title: (
                <Popover content="Insert new entry" trigger="hover">
                  <InsertEntryButton
                    icon={<PlusOutlined style={{ fontSize: 20 }} />}
                    onClick={async () => await handleFormOpen("Create entry")}
                    type="primary"
                  />
                </Popover>
              ),
              key: "actions",
              render: (record: TableType) => (
                <Flex justify="flex-end">
                  <Popover content="Edit entry" trigger="hover">
                    <Button
                      icon={<EditOutlined />}
                      onClick={async () =>
                        await handleFormOpen(
                          "Edit entry",
                          Number(record[idKey])
                        )
                      }
                    />
                  </Popover>
                  <Popover content="Delete entry" trigger="hover">
                    <DeleteEntryPopconfirm
                      title="Delete the entry"
                      description={`Are you sure to delete this entry?`}
                      onConfirm={async () =>
                        await onDelete(Number(record[idKey]))
                      }
                      okText="Yes"
                      cancelText="No"
                    >
                      <DeleteEntryButton icon={<DeleteOutlined />} />
                    </DeleteEntryPopconfirm>
                  </Popover>
                </Flex>
              ),
              align: "right" as "left" | "right" | "center" | undefined,
            },
          ],
        ]}
        dataSource={dataSource.map((item) => ({
          ...item,
          key: item[idKey],
        }))}
        pagination={
          pagination ?? {
            showSizeChanger: true,
            pageSizeOptions: ["8", "12", "16", "32", "64", "128"],
            defaultPageSize: 8,
          }
        }
        sticky={sticky ?? true}
        scroll={scroll ?? { y: tableHeight - 3.5 * cellHeight }}
        size={size ?? "small"}
      />
    </TableContainer>
  );
};
