import { Flex } from "antd";
import styled from "styled-components";

export const Banner = styled(Flex)`
  background-color: #550f0a;
  width: 100%;
  height: 4rem;
  margin-top: 5rem;
  box-shadow: 0rem 0.25rem 1rem black;
  position: absolute;
  left: 0;
`;
