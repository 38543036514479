import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { tokenRequest } from "authConfig";
import { useCallback, useMemo, useRef } from "react";

export const useAPI = () => {
  const { instance, accounts } = useMsal();
  const APIBaseUrl = process.env.REACT_APP_API_BASE_URL;

  let token = useRef<string | null>(null);

  const getToken = useCallback(async () => {
    if (accounts.length > 0) {
      try {
        const response = await instance.acquireTokenSilent({
          ...tokenRequest,
          account: accounts[0],
        });
        token.current = response.accessToken;
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(tokenRequest);
        } else {
          console.error("Token acquisition failed:", error);
        }
      }
    }
  }, [accounts, instance, token]);

  if (!APIBaseUrl) {
    console.error("API base URL is not set");
  }

  const service = useMemo(
    () => ({
      get: async (uri: string) => {
        try {
          await getToken();
          const headers: Record<string, string> = {
            "Content-Type": "application/json",
            Accept: "application/json",
          };
          if (token.current) {
            headers.Authorization = `Bearer ${token.current}`;
          }
          const response = await fetch(APIBaseUrl + uri, {
            method: "GET",
            headers,
          });
          return await response.json();
        } catch (error) {
          console.log(error);
        }
      },
      post: async (uri: string, body: string) => {
        try {
          await getToken();
          const headers: Record<string, string> = {
            "Content-Type": "application/json",
            Accept: "application/json",
          };
          if (token.current) {
            headers.Authorization = `Bearer ${token.current}`;
          }
          const response = await fetch(APIBaseUrl + uri, {
            method: "POST",
            body,
            headers,
          });
          const ok = response.status >= 200 && response.status < 300;

          return {
            ok: ok,
            status: response.status,
            json:
              response.status === 404
                ? { message: "Endpoint not found" }
                : await response.json(),
          };
        } catch (error) {
          return {
            ok: false,
            status: 0,
            json: { message: error },
          };
        }
      },
      put: async (uri: string, body: string) => {
        try {
          await getToken();
          const headers: Record<string, string> = {
            "Content-Type": "application/json",
            Accept: "application/json",
          };
          if (token.current) {
            headers.Authorization = `Bearer ${token.current}`;
          }
          const response = await fetch(APIBaseUrl + uri, {
            method: "PUT",
            body,
            headers,
          });
          const ok = response.status >= 200 && response.status < 300;

          return {
            ok: ok,
            status: response.status,
            json:
              response.status === 404
                ? { message: "Endpoint not found" }
                : await response.json(),
          };
        } catch (error) {
          return {
            ok: false,
            status: 0,
            json: { message: error },
          };
        }
      },
      delete: async (uri: string) => {
        try {
          await getToken();
          const headers: Record<string, string> = {
            "Content-Type": "application/json",
            Accept: "application/json",
          };
          if (token.current) {
            headers.Authorization = `Bearer ${token.current}`;
          }
          const response = await fetch(APIBaseUrl + uri, {
            method: "DELETE",
            headers,
          });
          const ok = response.status >= 200 && response.status < 300;

          return {
            ok: ok,
            status: response.status,
            json:
              response.status === 404
                ? { message: "Endpoint not found" }
                : { message: "Request Completed" },
          };
        } catch (error) {
          return {
            ok: false,
            status: 0,
            json: { message: error },
          };
        }
      },
    }),
    [APIBaseUrl, getToken]
  );

  return service;
};
