import routes from "routes/paths";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { MainContentWrapper } from "common/components/styled";
import { ProtectedRoute } from "common/components";

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    console.error(error.message);
  }

  render() {
    if (this.state.hasError) {
      window.location.href = "/";
      return null;
    }

    return this.props.children;
  }
}

export const PageContents = () => {
  return (
    <ErrorBoundary>
      <MainContentWrapper>
        <Routes>
          {Object.values(routes).map((route) => {
            if (route.protectedRoute) {
              return (
                <Route key={route.path} element={<ProtectedRoute />}>
                  <Route path={route.path} element={<route.component />} />
                </Route>
              );
            } else {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<route.component />}
                />
              );
            }
          })}
        </Routes>
      </MainContentWrapper>
    </ErrorBoundary>
  );
};
