import styled from "styled-components";
import { Flex } from "antd";
import { UserOutlined } from "@ant-design/icons";

export const MainContentWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  padding: var(--content-padding);
  height: calc(100vh - var(--navbar-height));
`;

export const Center = styled(Flex)`
  margin: auto;
  text-align: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
`;

export const StyledUserOutlinedIcon = styled(UserOutlined)`
  color: white;
  font-size: 1.2rem;
`;
