import { State } from "common/types";

export const stateCreate = <T>(
  state: State<T[]>,
  newObj: any,
  idKey: keyof T
) => {
  const newObjWithTempID: T = {
    ...newObj,
    [idKey]:
      Math.max(...state.get.map((instance) => Number(instance[idKey]))) + 1,
  };
  state.set([...state.get, newObjWithTempID]);
};

export const stateUpdate = <T>(
  state: State<T[]>,
  updatedObj: any,
  idKey: keyof T,
  id: number
) => {
  const index = state.get.findIndex((instance) => instance[idKey] === id);
  let tempState = [...state.get];
  tempState[index] = { ...updatedObj, [idKey]: id };
  state.set(tempState);
};

export const stateDelete = <T>(
  state: State<T[]>,
  idKey: keyof T,
  id: number
) => {
  let tempState = [...state.get];
  const index = tempState.findIndex((instance) => instance[idKey] === id);
  tempState.splice(index, 1);
  state.set(tempState);
};
