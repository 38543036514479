import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "App";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "authConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import { reportWebVitals } from "common/utils";
import "App.css";

// Create a root container
const rootElement = document.getElementById("root");

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  const msalInstance = new PublicClientApplication(msalConfig);
  msalInstance.initialize();

  root.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </React.StrictMode>
  );
} else {
  console.error("Cannot find the root element.");
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
