import React, { useEffect } from "react";
import { Form, Button, Modal, Flex } from "antd";
import dayjs from "dayjs";
import { FormField, FormHeader } from "common/components";
import { FormItem } from "common/types";
import { defaultFormValues, formInputType } from "common/enums";

type GenericFormProps<BackendType extends object> = {
  titleIcon: React.ReactNode;
  title: string;
  formItems: Array<FormItem<BackendType>>;
  visible: boolean;
  onCancel: () => void;
  onSubmit: (values: BackendType) => void;
};

export const GenericForm = <BackendType extends object>({
  titleIcon,
  title,
  formItems,
  visible,
  onCancel,
  onSubmit,
}: GenericFormProps<BackendType>) => {
  const [form] = Form.useForm();

  const initialValues = formItems.reduce((acc, item) => {
    if (item.initialValue) {
      acc[item.name] =
        item.inputType === formInputType.date
          ? dayjs(item.initialValue as Date)
          : item.inputType === formInputType.dateRange
          ? Array.isArray(item.initialValue)
            ? (item.initialValue as Array<Date>).map((date) => dayjs(date))
            : []
          : item.initialValue;
    } else {
      acc[item.name] =
        item.inputType === formInputType.date
          ? dayjs(defaultFormValues[item.inputType])
          : defaultFormValues[item.inputType];
    }
    return acc;
  }, {} as BackendType);

  useEffect(() => {
    if (visible) {
      form.resetFields();
      form.setFieldsValue(initialValues);
    }
  }, [visible, form, formItems, initialValues]);

  const handleSubmit = (values: BackendType) => {
    const processedValues = Object.keys(values).reduce((acc, key) => {
      const value = values[key as keyof BackendType];
      (acc as any)[key as keyof BackendType] = Array.isArray(value)
        ? value.map((elem) => (dayjs.isDayjs(elem) ? elem.toDate() : elem))
        : dayjs.isDayjs(value)
        ? value.toDate()
        : value;
      return acc;
    }, {} as BackendType);

    onSubmit(processedValues);
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      title={<FormHeader icon={titleIcon} title={title} />}
      open={visible}
      onCancel={handleCancel}
      footer={[
        <Flex key="footer" justify="space-between">
          <Button key="cancel" onClick={onCancel}>
            Cancel
          </Button>
          <Button key="submit" type="primary" onClick={() => form.submit()}>
            Submit
          </Button>
        </Flex>,
      ]}
      centered={true}
      style={{
        padding: "0.5rem",
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        style={{
          maxHeight: "60vh",
          overflowY: "scroll",
        }}
      >
        {formItems.map((item) => (
          <FormField<BackendType> key={String(item.name)} formItem={item} />
        ))}
      </Form>
    </Modal>
  );
};
