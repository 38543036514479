import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "authConfig";
import { Button, Flex, Image, Typography } from "antd";
import { images } from "common/enums";

export const LoginButton = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  };

  return (
    <Button
      onClick={handleLogin}
      style={{
        padding: "1.2rem 0.6rem",
      }}
    >
      <Flex gap={"0.5rem"}>
        <Image
          src={images.microsoft}
          preview={false}
          style={{ height: "1.5rem" }}
        />
        <Typography style={{ fontSize: "1.1rem" }}>
          Sign in with Microsoft
        </Typography>
      </Flex>
    </Button>
  );
};
