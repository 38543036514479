import React from "react";
import { Divider, Flex, Typography } from "antd";

export const PageTitle = (props: { title: string; icon: React.ReactNode }) => {
  const { Title } = Typography;

  return (
    <>
      <Flex vertical={false}>
        <Flex
          align="center"
          style={{
            fontSize: "1.5rem",
            marginRight: "0.5rem",
            color: "white",
          }}
        >
          {props.icon}
        </Flex>
        <Title level={3}>{props.title}</Title>
      </Flex>
      <Divider
        style={{
          borderColor: "white",
          paddingBottom: "1.6rem",
        }}
      />
    </>
  );
};
