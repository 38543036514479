import React from "react";
import { Center } from "common/components/styled";

export const ColourBlock = (props: {
  colour: string;
  size: string;
  borderRadius: string;
}) => {
  return (
    <Center>
      <figure
        style={{
          width: props.size,
          height: props.size,
          backgroundColor: props.colour,
          borderRadius: props.borderRadius,
        }}
      />
    </Center>
  );
};
