import React from "react";
import { MenuProps } from "antd";
import { sidebar } from "common/enums/sidebar";
import { useNavigate } from "react-router-dom";
import { StyledSidebar, StyledVerticalMenu } from "common/components/styled";
import { State } from "common/types/generics";

const items: MenuProps["items"] = sidebar.map(({ path, ...items }) => items);

export const Sidebar = (props: { page: State<string> }) => {
  const navigate = useNavigate();

  const onClick: MenuProps["onClick"] = (e) => {
    props.page.set(e.key);
    const pageObject = sidebar.find((item) => item.key === e.key);
    if (pageObject !== undefined) {
      navigate(pageObject.path);
    }
  };

  return (
    <StyledSidebar width={200}>
      <StyledVerticalMenu
        mode="inline"
        selectable
        selectedKeys={[props.page.get]}
        onClick={onClick}
        items={items}
      />
    </StyledSidebar>
  );
};
