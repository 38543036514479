import asyncComponentLoader from "common/utils/loader";
import { Routes } from "routes/types";

const routes: Routes = {
  Login: {
    component: asyncComponentLoader(() => import("pages/Login/Login")),
    path: "/login",
    title: "Login",
    protectedRoute: false,
  },
  Home: {
    component: asyncComponentLoader(() => import("pages/Home/Home")),
    path: "/",
    title: "Home",
    protectedRoute: true,
  },
  Clients: {
    component: asyncComponentLoader(() => import("pages/Clients/Clients")),
    path: "/clients",
    title: "Clients",
    protectedRoute: true,
  },
  Users: {
    component: asyncComponentLoader(() => import("pages/Users/Users")),
    path: "/users",
    title: "Users",
    protectedRoute: true,
  },
  Teams: {
    component: asyncComponentLoader(() => import("pages/Teams/Teams")),
    path: "/teams",
    title: "Teams",
    protectedRoute: true,
  },
  SLA: {
    component: asyncComponentLoader(() => import("pages/SLA/SLA")),
    path: "/sla",
    title: "SLA",
    protectedRoute: true,
  },
  Standby: {
    component: asyncComponentLoader(() => import("pages/Standby/Standby")),
    path: "/standby",
    title: "Standby",
    protectedRoute: true,
  },
  Leave: {
    component: asyncComponentLoader(
      () => import("pages/Unavailability/Unavailability")
    ),
    path: "/unavailability",
    title: "Unavailability",
    protectedRoute: true,
  },
  SupportHours: {
    component: asyncComponentLoader(
      () => import("pages/SupportHours/SupportHours")
    ),
    path: "/support-hours",
    title: "Support Hours",
    protectedRoute: true,
  },
};

export default routes;
