import type { AnyProps, LoadComponent, LoaderDefaultOptions } from "./types";
import { loader as loaderDefaultOptions } from "common/enums";
import { LoadingSpinner } from "common/components/Loader";
import asyncComponentLoader from "./Loader";

const configuredAsyncComponentLoader = (
  loadComponent: LoadComponent,
  additionalProps: AnyProps = {},
  loaderOptions: LoaderDefaultOptions = loaderDefaultOptions,
  FallbackWaiting = LoadingSpinner
) => 
  asyncComponentLoader(
    loadComponent,
    additionalProps,
    loaderOptions,
    FallbackWaiting
  ); 

export { loaderDefaultOptions};

export default configuredAsyncComponentLoader;