export enum formInputType {
  text,
  paragraph,
  number,
  date,
  dateRange,
  dropdown,
  checkbox,
}

export const defaultFormValues: { [key in formInputType]: any } = {
  [formInputType.text]: "",
  [formInputType.paragraph]: "",
  [formInputType.number]: 0,
  [formInputType.date]: null,
  [formInputType.dateRange]: null,
  [formInputType.dropdown]: null,
  [formInputType.checkbox]: false,
};
