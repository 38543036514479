import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "antd";

export const LogoutButton = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/login",
    });
  };

  return <Button onClick={handleLogout}>Logout</Button>;
};
