import styled from "styled-components";
import { Image, Menu } from "antd";
import { Header } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";

export const Nav = styled(Header)`
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 2.3rem;
  gap: 0.5rem;
  border-bottom: 1px solid var(--layout-border-color);
`;

export const Logo = styled(Image)`
  min-width: 2rem;
`;

export const StyledSidebar = styled(Sider)`
  border-right: 1px solid var(--layout-border-color);
  height: auto;
`;

export const StyledVerticalMenu = styled(Menu)`
  flex: 1;
  background: transparent;
  border: 0;
`;

export const StyledHorizontalMenu = styled(Menu)`
  flex: 1;
  background: transparent;
  min-width: 0;
  margin: 0 1rem;
  border: 0;
  gap: 0.25rem;
`;
